import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Wrapper from "./Wrapper"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HeroHeader({title, children}) {
  return (
    <Wrapper className="bg-white">
      <main>
        <div>
          <div className="relative mb-8">
            <div className="absolute inset-x-0 bottom-0" />
            <div className="mx-auto  sm:px-6 lg:px-8">
              <div className="relative  sm:overflow-hidden ">
                <div className="absolute pt-4 h-[400px]">
                  {children}

                </div>
                <div className="relative px-4 h-[400px] py-16 sm:px-6 sm:py-24 lg:py-16 lg:px-16">
                <p className=" mt-16 pb-4 max-w-lg text-xl text-white sm:max-w-3xl">
                    Máme tu aj
                  </p>
                  <h1 className=" text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="inline-block px-4 py-2 text-white rotate-[1deg] bg-yellow"><span className=" inline-block rotate-[-1deg]">{title}</span></span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    </Wrapper>
  )
}