/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import "./layout.css"
 import Wrapper from "./Wrapper"
 
 
 const Intro = ({ children, data }) => {

 
   return (
     <>
       
       <Wrapper>
        <div className="grid grid-cols-12 gap-2 pb-12">
         <div className="md:col-span-7 col-span-12 md:p-0 md:pr-12 mx-auto">
          { children }
         </div>
         <div className="md:col-span-5 col-span-12 ml-6 md:ml-0">
             <h6 className="xl:text-xl md:text-md block xl:pt-20 pt-4 md:pb-6 pb-2 leading-8 italic">{data.subheader}</h6>
             <h2 className=" xl:text-6xl text-3xl leading-tight font-semibold">{data.header}</h2>
             <p className="block  xl:text-xl leading-normal pt-4">{data.text}</p>
         </div>
        </div>
       </Wrapper>
    
     </>
   )
 }
 
 export default Intro
 