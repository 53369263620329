/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import "./layout.css"
import Wrapper from "./Wrapper"
import { StaticImage } from "gatsby-plugin-image"


const Intro = ({ props }) => {

  return (
    <>
      
      <Wrapper>
       <div className="grid grid-cols-12 gap-2 pb-12">
        <div className="md:col-span-7 col-span-12 md:p-0 md:pr-12">
        <div className="hidden md:block"><StaticImage placeholder="blurred" className=""  imgClassName="" src="../../static/images/landing/landing01.png" alt="skiers" /></div>
        <div className="md:hidden block"><StaticImage placeholder="blurred" className=""  imgClassName="" src="../../static/images/subheaders/subheader03-small.png" alt="skiers" /></div>
        </div>
        <div className="md:col-span-5 col-span-12">
            <h6 className="xl:text-xl md:text-md block xl:pt-20 pt-4 md:pb-6 pb-2 leading-8 italic">U nás si každý vyberie</h6>
            <h2 className="xl:text-6xl text-3xl leading-tight font-semibold">Tvoja požičovňa lyžiarskej, bežeckej, snowboardovej výstroje</h2>
            <p className="block lg:w-3/4 xl:text-xl leading-normal pt-4">Požičovňa výstroje, predaj nového a použitého lyžiarskeho a snowboardového materiálu pre deti a dospelých, oprava Vašich lyží a snowboardov, to všetko na jednom mieste u nás.</p>
        </div>
       </div>
      </Wrapper>
   
    </>
  )
}

export default Intro
