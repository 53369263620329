import * as React from "react"
import {useState, useEffect} from 'react';
import axios from "axios";
import Wrapper from "./Wrapper"


// you need to pass tags as string of needed tags
// for example if you need tags children, winter and cats
// tags must be "children,winter,cats"
// and it will return you all active records, that contain at least one of this tags

const Pricelist = ({tags}) => {
    const [pricelist, setPricelist] = useState([]);
    const headers = {
      'Authorization': process.env.GATSBY_DIRECTUS_TOKEN,
      'Content-Type': 'application/json'
    };
    const url = `${process.env.GATSBY_API_URL}/get_pricelist/?tags=${tags}`

    useEffect(() => {
         axios({
          url: url,
          method: 'GET',
          headers: headers
        })
          .then(response => setPricelist(response.data))
            .catch(error => {
                    console.log(error);
            });
    }, []);

    return (
        <Wrapper>
        <div className="py-24">
            <table className="w-full table-auto">
                <tr className="h-28 text-lg bg-yellow">
                    <th className="box-border border-r border-white w-[278px]">Sortiment</th>
                    { tags.includes("kratkodobe") ? <th className="w-[137px] box-border border-x border-white text-center">1 deň</th>: null}
                    { tags.includes("kratkodobe") ? <th className="w-[137px] box-border border-x border-white text-center">2 dni</th>: null}
                    { tags.includes("kratkodobe") ? <th className="w-[137px] box-border border-x border-white text-center">3 dni</th>: null}
                    { tags.includes("kratkodobe") ? <th className="w-[137px] box-border border-x border-white text-center">4 dni</th>: null}
                    { tags.includes("kratkodobe") ? <th className="w-[137px] box-border border-x border-white text-center">5 dní</th>: null}
                    { tags.includes("kratkodobe") ? <th className="w-[137px] box-border border-x border-white text-center">6 dní</th>: null}
                    { tags.includes("kratkodobe") ? <th className="w-[137px] box-border border-x border-white text-center">Každý ďalší deň</th>: null}
                    { tags.includes("sezonne") ? <th className="w-[137px] box-border border-l border-white text-center">Sezóna</th>: null}
                    { tags.includes("kurzy") ? <th className="w-[137px] box-border border-l border-white text-center">Rezervačný poplatok</th>: null}
                    { tags.includes("kurzy") ? <th className="w-[137px] box-border border-l border-white text-center">Cena</th>: null}
                </tr>
                {pricelist ? pricelist.map((value, index) => {
                    return(
                        <tr key={index} className="bg-white">
                            <td className="h-28 box-border border border-gray-200 pl-8"><div className="text-black text-lg font-medium">{value.assortment}</div>
                                <div className="text-black text-base font-normal">{value.description}</div></td>
                            { tags.includes("kratkodobe") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.day1 || "-"} €</td> : null}
                            { tags.includes("kratkodobe") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.day2 || "-"} €</td> : null}
                            { tags.includes("kratkodobe") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.day3 || "-"} €</td> : null}
                            { tags.includes("kratkodobe") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.day4 || "-"} €</td> : null}
                            { tags.includes("kratkodobe") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.day5 || "-"} €</td> : null}
                            { tags.includes("kratkodobe") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.day6 || "-"} €</td> : null}
                            { tags.includes("kratkodobe") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.another_day  || "-"} €</td> : null}
                            { tags.includes("sezonne") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.season  || "-"} €</td> : null}
                            { tags.includes("kurzy") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.reserve  || "-"} €</td> : null}
                            { tags.includes("kurzy") ? <td className="h-28 box-border border border-gray-200 text-center text-black">{value.lyziarsky  || "-"} €</td> : null}
                        </tr>
                    )
                }) : null}
            </table>
            { tags.includes("sezonne") ? <span>* akciová cena k detskému kompletu</span> : null}
        </div>
        </Wrapper>
    )
}

export default Pricelist
