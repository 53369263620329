import * as React from "react"
import {useState, useEffect} from 'react';
import axios from "axios";
import "./layout.css"
import Map from "./map"
import LandingContact from "./LandingContact"


const Contact = ({ children }) => {

  const [hours, setHours] = useState();
  const headers = {
    'Authorization': process.env.GATSBY_DIRECTUS_TOKEN,
    'Content-Type': 'application/json'
  };
  const url = `${process.env.GATSBY_DIRECTUS_URL}/items/Opening_hours?filter[status][_eq]=published`;
  useEffect(() => {
       axios({
        url: url,
        method: 'GET',
        headers: headers
      })
        .then(response => setHours(response.data))
          .catch(error => {
                  console.log(error);
          });
  }, []);
  return (
    <>
      <div className="hidden xl:block"><LandingContact></LandingContact></div>
      <div className="container xl:max-w-7xl md:max-w-5xl mx-auto sm:px-6 pb-16 xl:hidden block">
       <div className="grid grid-cols-12 md:gap-8 lg:pt-16 xl:pt-8">
       <div className="md:col-span-5 col-span-12 p-8 sm:p-0">
            <div className="grid grid-cols-2 gap-8 pb-4">
              <div className="col-span-2">
                    <h6 className="xl:text-xl md:text-md block  pb-2 leading-8 italic">Kontakt</h6>
                    <h2 className="xl:text-6xl text-3xl leading-tight font-semibold">Kde nás nájdete</h2>
              </div>
              <div className="xl:col-span-1 col-span-2">
                <h5 className="font-medium">Lokalita</h5><br/>
                <p>
                  Gorkého, 6 <br />
                  (oproti  Hotelu Yasmin)  <br />
                  04001 Košice  <br />
                  zobraziť na mape  <br />
                </p>
              </div>
              <div className="xl:col-span-1 col-span-2">
                <h5 className="font-medium">Spojenie</h5><br/>
                <p>
                +421 911 41 41 40<br />
                <br />
                <a href="https://www.facebook.com/profile.php?id=100057536894105">Facebook</a> <a href="https://www.instagram.com/pozicovnalyzikosice/">Instagram</a><br /><br />
                info@pozicovnalyzikosice.sk
                </p>
              </div>
            </div>

            {/* <p className="block w-3/4 text-xl leading-normal pt-4">kontakt</p> */}
        </div>
        <div className="md:col-span-7 col-span-12 bg-yellow md:p-12 p-8 lg:p-16 pb-40 ">
        {hours ?  hours.data.map((hour) => {
        return(
        <div className="grid grid-cols-2 gap-8 pb-8">
            <div className="col-span-2 text-3xl font-medium">Otváracie hodiny {hour.date_interval}</div>
            <div className="xl:col-span-1 col-span-2">
              <p>
                Pondelok – Piatok {hour.working_days} <br /><br />
                Sobota {hour.saturday} <br /><br />
                Nedeľa {hour.sunday} <br/><br />
              </p>
              <p> {hour.note1}</p>
              <p> {hour.note2}</p>
            </div>
            </div> )}): null}
        </div>
        <div className="col-span-12  h-full md:aspect-video lg:pt-12 md:block  m">
          <Map w='100%' h='100%' />
        </div>
       </div>
       <div className="grid grid-cols-12 md:gap-8 pt-16 h-[300px] sm:h-[500px] relative block md:hidden">
       <div className="col-span-12 sm:h-[600px] h-[400px] w-full md:aspect-video aspect-square lg:pt-12 md:block px-8 md:px-0 absolute -top-32">
          <Map w='100%' h='100%' />
        </div>
       </div>
      </div>
   
    </>
  )
}

export default Contact
